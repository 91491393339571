.connect-footer {
    width: 100%;
    height: 72px;
    display: flex;
    justify-content: center;
    align-items: center;

    ion-buttons {
        padding-left: 16px;
    }

    a {
        padding-left: 16px;
        color: black;
        text-decoration: none;
    }

    ion-icon {
        color: #ff8c00;
    }
}

@media (max-width: 472px) {
    .connect-footer {
        flex-direction: column-reverse;

        a {
            padding-bottom: 8px;
            padding-left: 0;
        }

        ion-buttons {
            padding: 8px 0;
        }

    }
}