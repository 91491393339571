.mobile-side-menu {
  --min-width: 100%;

  &__header {
    box-shadow: none;
  }

  &__toolbar {
    max-width: 1140px;
    margin: auto;
  }

  &__list {
    height: 100%;
    padding: 0 12px 12px;

    &-item {
      --padding-start: 0;
      --border-color: transparent;
      --border-radius: 12px;
      padding: 12px 0;
      font-size: 20px;

      &-icon {
        padding: 0 16px;
      }
    }
  }

  &__subitem-list {
    padding: 0;
  }

  &__subitem {
    --border-color: transparent;
    --inner-padding-start: 50px;
    --border-radius: 12px;
  }

  &__social-media-buttons {
    justify-content: center;
    padding-bottom: 60px;
  }

  .connect-footer {
    padding-bottom: 16px;
  }
}
