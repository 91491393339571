.iframe-container {
    width: 100%;
    display: flex;
    align-content: center;
    justify-content: center;
    position: relative;
    padding-bottom: 56.25%;
    /* 16:9 */
    padding-top: 25px;
    height: 0;

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    &.mobile-padding {
        iframe {
            padding: 32px;
        }
    }
}

@media (max-width: 768px) {
    .iframe-container {
        &.mobile-padding {
            iframe {
                padding: 20px;
            }
        }
    }
}