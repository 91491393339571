.nav-bar {
    &__header {
      box-shadow: none;
    }

    &__toolbar {
      margin: auto;
      color: #000000;
    }

    

   
  
    &__button-container {
      .nav-bar__text-button,
      .nav-bar__social-button {
        --ripple-color: var(--background);
      }
  
      .nav-bar__text-button {
        padding: 0 20px;
      }

      ion-icon {
        color: #ff8c00;
      }
    }
  
    &__popover {
      --offset-y: 12px;
      --offset-x: -64px;
  
      &-item {
        --padding-start: 0;
        cursor: pointer;
      }
  
      &-label {
        padding-left: 12px;
      }
    }

   

  }
