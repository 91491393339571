.home-content {
  &__text {
    padding: 0 16px;
    margin: auto;
    max-width: 1140px;
    font-size: 36px;
    font-weight: bold;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    &.red {
      background: #ff8c00;
      color: #fff;
      max-width: unset;
      padding: 24px;
      height: 240px;
      letter-spacing: 2px;
    }

    &.sub {
      font-weight: 500;
      font-size: 32px;
    }
  }

  &__subtitle {
    padding-top: 8px;
    font-size: 32px;
  }

  &__text-highlighted {
    color: #ff8c00;
  }

  &__cards-container {
    display: flex;
    flex-wrap: wrap;
  }

  &__card {
    width: 100%;
    margin: 0;
    margin-bottom: -4px;
    position: relative;
    max-height: 500px;
    overflow: hidden;
  }

  ion-router-link {
    z-index: 12;
    cursor: pointer;
  }

  &__card-header,
  &__card-background,
  ion-router-link {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 4px;
  }

  &__card-header {
    z-index: 11;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 24px;
    letter-spacing: 3px;
  }

  &__card-background {
    z-index: 10;
    background: #000;
    opacity: 0.4;
  }
}

@media (min-width: 658px) {
  .home-content {
    &__card-background {
      &.last {
        bottom: 0;
      }
    }
  }
} 

@media (min-width: 768px) {
  .home-content {
    &__text {
      font-size: 48px;
    }
    &__card {
      width: 50%;
      max-height: unset;
    }

    &__card-background {
      &.last {
        bottom: 4px;
      }
    }
  }
}

@media (min-width: 1024px) {
  .home-content {
    --ion-padding: 32px; 
  }
}


