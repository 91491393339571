iframe {
    border: none;
}

.two-column {
    padding-top: 24px;

    img {
        display: block;
        max-height: 700px;
        width: auto;
        height: auto;
        align-self: center;
        justify-self: center;
    }

    display: flex;
    align-items: center;
    justify-content: center;

    .left,
    .right {
        width: 50%;
        padding: 24px;
        display: grid;

        .title {
            font-size: 24px;
            padding: 24px;
            font-weight: 400;
            letter-spacing: 2px;

            &.main {
                font-weight: bold;
            }
        }

        .subtitle {
            font-size: 18px;
            padding: 0 0 24px 32px;
        }

        .photo-grid {
            display: flex;
            flex-direction: column;
            justify-self: center;

            .row {
                display: flex;

                img {
                    display: block;
                    width: auto;
                    height: auto;
                    max-height: 200px;
                    padding: 16px;
                }
            }
        }
    }
}

@media (max-width: 1200px) {
    .two-column {

        .left,
        .right {
            .photo-grid {
                .row {
                    img {
                        max-height: 150px;
                    }
                }
            }
        }
    }
}

@media (max-width: 865px) {
    .two-column {

        .left,
        .right {
            .photo-grid {
                .row {
                    img {
                        max-height: 125px;
                    }
                }
            }
        }
    }
}

@media (max-width: 768px) {
    .two-column {
        padding-top: 0;
        flex-direction: column;

        .left,
        .right {
            width: 100%;
            padding-top: 0;

            .title {
                font-size: 20px;
                padding: 16px;
                font-weight: 400;
                letter-spacing: 2px;

                &.main {
                    font-weight: bold;
                }
            }

            .subtitle {
                font-size: 16px;
                padding: 0 0 12px 16px;
            }

            &.mobile-no-padding {
                padding: 0;
            }
        }

        &.mobile-reverse {
            flex-direction: column-reverse;
        }
    }
}