.list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-top: 24px;

    .item {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        padding-bottom: 64px;
    }

    .item-title {
        font-size: 48px;
        padding-bottom: 24px;
        letter-spacing: 5px;
    }

    .item-subtitle {
        a {
            font-size: 32px;
            color: black;
            text-decoration: none;
        }
        ion-icon {
            font-size: 5rem !important;
            padding: 1rem;
            color:  #ff8c00;
        }
    }
}