.photography {
  &__image-container {
    column-count: 1;
    column-gap: 4px;
  }

  &__image {
    width: 100%;
    break-inside: avoid;
    object-fit: cover;
  }
}

@media (min-width: 500px) {
  .photography {
    &__image-container {
      column-count: 2;
    }
  }
}

@media (min-width: 1024px) {
  .photography {
    &__image-container {
      max-width: 1140px;
      margin: auto;
      column-count: 3;
    }
  }
}
